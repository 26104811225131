import React, { useState } from 'react'
import { checkPrivileges } from '../../../../Helpers'
import TextField from '../../../common/TextField'
import { Grid } from '@mui/material'
import Button from '../../../common/Button'
import { notifyError, notifySuccess } from '../../../../Utils/CustomNotifcations'
import { updateAccount } from '../../../../Actions/Accounts'
import { ServerInfo } from '../../../../Actions/serverSetting'

const ContractNumber = (props) => {
  const originalcontractNumber = props.selectedAccount?.contractNumber || '';
  const [contractNumber, setcontractNumber] = useState(originalcontractNumber);

  const handleChange = (event) => {
    setcontractNumber(event.target.value);
  };

  const handleUpdateAccount = () => {
    let accountData = {
      ...props.selectedAccount,
      contractNumber: contractNumber
    };

    fetch(`/api/accounts/${props.selectedAccount.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(accountData),
    })
      .then((response) => {
        return response.json().then((res) => {
          if (!response.ok) {
            throw new Error(res?.message || "API request failed");
          }
          return res;
        });
      })
      .then((res) => {
        let account = res.data;

        if (res.status === "success") {
               props.dispatch(
                          notifySuccess("contractNumberIsChanged")
                        );
          props.updateSelectedAccount(account);
          props.dispatch(updateAccount(account));

          if (props.selectedAccount.id === 1) {
            props.dispatch(ServerInfo(account));
          }
        } else if (res?.statusCode === "440") {
          window.location.replace("/login");
        } else if (res.statusCode) {
          let err = res?.message.split(":");
          err[1] = err[1].replace(")", "");
          props.dispatch(notifyError(props.translate(err[1])));
        }
      })
      .catch((error) => {
        props.dispatch(notifyError(error));
      });
  };

  const isUnchanged = contractNumber === originalcontractNumber;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="contractNumber"
            label={props.translate("contractNumber")}
            type="text"
            value={contractNumber}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            fullWidth
            InputProps={{
              readOnly: !props.updateOption || checkPrivileges("userUpdate") ? false : true,
            }}
          />
        </Grid>
        <Grid item md={10} style={{ display: "flex", justifyContent: "flex-start", marginTop: "1rem" }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpdateAccount}
            disabled={props.logInUser?.userType !== -1 || isUnchanged}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContractNumber;
