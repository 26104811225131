import React, { Component, Fragment } from 'react'
import Units from './Components/Units'
import Group from './Components/Group'
import Dashboard from './Components/Dashboard'
import Widgets from './Components/Widgets'
import Role from './Components/Roles'
import Maps from './Components/Maps'
import ChangedPasword from './Components/changedPassword'
import Reports from './Components/Reports'
import User from './Components/Uses'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import { checkPrivileges } from '../../../Helpers'
// import { withSnackbar } from 'notistack'
import UpdateUserDetail from './Components/updateUser'
import Loader from '../../../Layout/Loader'
import axios from 'axios'
import Breadcrumb from '../../common/Breadcrumb'
import Style from 'style-it'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from '../../common/Button'
import UpdateThemes from '../../Accounts/accountDetail/Components/updateThemes'
import { Serversettings } from '../../ServerSettings/serverSettings'
import AccountUser from '../../Accounts/accountDetail/Components/accountUses'
import AccountUnits from '../../Accounts/accountDetail/Components/accountUnits'
import {WaslApi} from '../../ServerSettings/waslApi'
import { notifyError } from '../../../Utils/CustomNotifcations'
import withTranslationWrapper from '../../../HOC/HocTranslate'
import ContractNumber from './Components/ContractNumber'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})

class viewUserDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 'edit',
      units: '',
      selectedUser: '',
      selectedUserLimits: '',
      selectedUnitsLimits: '',
      pUserLimits: '',
      pUnitLimits: '',
      loginDeviceIds: '',
      loader:true,
    }
  }
  
  componentWillMount () {
    // if(this.props.logInUser.userType === -1){ 
      fetch(`/api/accounts?accountId=${this.props.selectItemId.accountId}`)
      .then(response => {
        response.json().then(res=>{
          if (res.status === 'success') {
              this.setState({
                selectedAccount:res.data[0],
                loader:false
              })
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(this.props.translate(err[1]))
              )
            }
        })
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
    // }
    
    if (this.props.logInUser.userType !== 6) {
      fetch(
        `/api/devices/get?userId=${this.props.selectItemId.id}&all=true&limit=-1`
      ).then(response => {
        if (response.ok) {
          response.json().then(devices => {
            if(devices.status ==='success'){   
              this.setState({
                units: (devices && devices.data) || []
              })
            }
            else if(devices?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(devices.statusCode){
              var err = devices?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(this.props.translate(err[1]))
              )
            }
          })
        }
        else{
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
    }
    let allIds = []
    if (this.props.logInUser.userType !== 6) {
    axios
      .get(`/api/devices/linked?userId=${this.props.logInUser.id}`)
      .then(resp => {
        let res =resp.data
        if(res.status === 'success'){
          res.data.map(userId => {
            allIds.push(userId.entityId)
          })
          this.setState({
            loginDeviceIds: allIds
          })
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            notifyError(this.props.translate(err[1]))
          )
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
    }
    this.props.users.map(item => {
      if (item.id === this.props.selectItemId.id) {
        if (
          item.parentId &&
          parseInt(item.parentId) !== parseInt(this.props.logInUser.id)
        ) {
          //count call for parent devices
          fetch(`/api/devices/count?userId=${item.parentId}`).then(response => {
            if (response.ok) {
              response.json().then(limits => {
                if(limits.status ==='success'){ 
                  this.setState({
                    pUnitLimits: limits.data,
                    selectedUser: item
                  })
                }
                else if(limits?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(limits.statusCode){
                  var err = limits?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  )
                }
              })
            }
            else{
              throw response
            }
          })
          .catch(e => {
            this.props.dispatch(notifyError('somethingWentWrong'))
          })
          //count call for parent users
          fetch(`/api/users/count?userId=${item.parentId}`).then(response => {
            if (response.ok) {
              response.json().then(limits => {
                if(limits.status ==='success'){ 
                  this.setState({
                    pUserLimits: limits.data,
                    selectedUser: item
                  })
                }
                else if(limits?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(limits.statusCode){
                  var err = limits?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  )
                }
              })
            }
            else{
              throw response
            }
          })
          .catch(e => {
            this.props.dispatch(notifyError('somethingWentWrong'))
          })
        } else {
          this.setState({
            selectedUser: item,
            pUserLimits: this.props.parentUserLimits,
            pUnitLimits: this.props.parentUnitLimits
          })
        }
      }
    })  
    
    //count call for devices
    fetch(`/api/devices/count?userId=${this.props.selectItemId.id}`).then(
      response => {
        if (response.ok) {
          response.json().then(limits => {
            if(limits.status ==='success'){ 
              this.setState({
                selectedUnitsLimits: limits.data
              })
            }
            else if(limits?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(limits.statusCode){
              var err = limits?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(this.props.translate(err[1]))
              )
            }
          })
        }
        else{
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
    //count call for users
    fetch(`/api/users/count?userId=${this.props.selectItemId.id}`).then(
      response => {
        if (response.ok) {
          response.json().then(limits => {
            if(limits.status ==='success'){ 
              this.setState({
                selectedUserLimits: limits.data
              })
            }
            else if(limits?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(limits.statusCode){
              var err = limits?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(this.props.translate(err[1]))
              )
            }
          })
        }
        else{
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
   
  }

  updateSelectedAccount =(item)=>{
      this.setState({ selectedAccount:{...item }})
  }

  UpdateSelectedItem = item => {
    this.setState({
      selectedUser: item
    })
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render () {
    const { classes , selectItemId} = this.props
    const {
      value,
      selectedUser,
      selectedUserLimits,
      selectedUnitsLimits,
      pUserLimits,
      pUnitLimits
    } = this.state
    let tabName = 'edit'
    if(value==='user'){tabName=this.props.translate('user')}
    else if(value==='unit'){tabName=this.props.translate('units')}
    else if(value==='group'){tabName=this.props.translate('groups')}
    else if(value==='dashboard'){tabName=this.props.translate('Dashboard')}
    else if(value==='widget'){tabName=this.props.translate('Widgets')}
    else if(value==='role'){tabName=this.props.translate('role')}
    else if(value==='map'){tabName=this.props.translate('map')}
    else if(value==='changePassword'){tabName=this.props.translate('changePassword')}
    else if(value==='report'){tabName=this.props.translate('report')}
    else if(value==='themeSettings'){tabName=this.props.translate('themeSettings')}
    else if(value==='serverSettings'){tabName=this.props.translate('serverSettings')} 
    else if(value==='accountUsers'){tabName=this.props.translate('accountUser')}
    else if(value==='accountUnits'){tabName=this.props.translate('accountUnits')}
    else if(value==='waslApi'){tabName=this.props.translate('waslApiKey')}
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
    if (
      selectedUser &&
      selectedUserLimits &&
      selectedUnitsLimits &&
      pUserLimits &&
      pUnitLimits 
    ) {
        return (
          <div className={classes.root}>
            <Breadcrumb
              itemName={this.props.selectItemName}
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate("usersDetails")}
              backLink={<Button size="small" onClick={this.props.onClose}>{this.props.translate('sharedBack')}</Button>}
            />
            <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
            width:250px;
          }
            .custom-tabs{
             display:flex;

            }
          .custom-tab-button2 .MuiTab-wrapper {
             display:flex;
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected2 {
            color: ${this.props.themecolors["500"]};
          } 
             .Mui-selected  {
            color: ${this.props.themecolors["500"]}  !important;
          }
          .tab-vertial-wrapper {
    background: ${this.props.themecolors.backgroundColor};
  }
          `}</Style>
            <div className="v-tabs">
            <AppBar elevation={0} position="static"
             style={{background: 'transparent',}} 
             className="tab-vertial-wrapper">
              <Tabs
                value={value}
                onChange={this.handleChange}
                orientation={orientation}
                classes={{ root: 'custom-tabs' }}
                TabIndicatorProps={{
                  style: { background: 'transparent' }
                }}
              >
                <Tab
                  value="edit"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("sharedEdit")}
                    </div>
                  }
                />
                {(this.state.selectedUser.userType>2&&this.props.logInUser.userType === -1)&&
                  <Tab
                  value="contractNumber"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("contractNumber")}
                    </div>
                  }
                />
                }
          
                {this.state.selectedUser.userType !== 1 && this.props.logInUser.userType !== 6 && (
                  <Tab
                    value="user"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    style={{alignItems:"flex-start", }}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <KeyboardArrowRightIcon color="currentColor" />
                        {this.props.translate("users")}
                      </div>
                    }
                  />
                )}
               {this.props.logInUser.userType !== 6 && (
                <Tab
                  value="unit"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("deviceTitle")}
                    </div>
                  }
                />)}
                {this.props.logInUser.userType !== 6 && (
                <Tab
                value="group"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("groups")}
                    </div>
                  }
                />)}
                {checkPrivileges('dashboard') && (
                <Tab
                value="dashboard"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("dashboard")}
                    </div>
                  }
                />)}
                {checkPrivileges('dashboard') && (
                <Tab
                value="widget"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("widgets")}
                    </div>
                  }
                />)}
                {(this.props.logInUser.userType === -1 || this.props.logInUser.userType === 5) && (
                  <Tab
                    value="role"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    style={{alignItems:"flex-start", }}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <KeyboardArrowRightIcon color="currentColor" />
                        {this.props.translate("role")}
                      </div>
                    }
                  />
                )}
                <Tab
                  value="map"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("mapTitle")}
                    </div>
                  }
                />
                <Tab
                  value="changePassword"
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  style={{alignItems:"flex-start", }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon color="currentColor" />
                      {this.props.translate("changePassword")}
                    </div>
                  }
                />
                {this.state.selectedUser.userType !== 1 &&
                  checkPrivileges('userLinkRp') &&
                  checkPrivileges('userUnlinkRp') && (
                    <Tab
                      value="report"
                      classes={{
                        root: 'custom-tab-button2',
                        selected: 'custom-tab-button-selected2'
                      }}
                      style={{alignItems:"flex-start", }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon color="currentColor" />
                          {this.props.translate("report")}
                        </div>
                      }
                    />
                  )}
                {checkPrivileges('themeChange') && this.state.selectedUser.userType !== 2 &&  this.state.selectedUser.userType !== 1 &&
                 (<Tab
                    value="themeSettings"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    style={{alignItems:"flex-start", }}
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <KeyboardArrowRightIcon color="currentColor" />
                            {this.props.translate("themeSettings")}
                          </div>
                        }
                  />)}
                  {this.props.logInUser.userType === -1 && (selectItemId&&selectItemId.userType !== 2) && (selectItemId&&selectItemId.userType !==1) && (
                  <Tab
                    value="serverSettings"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    style={{alignItems:"flex-start", }}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <KeyboardArrowRightIcon color="currentColor" />
                        {this.props.translate("serverSettings")}
                      </div>
                    }
                   />)}
                  {this.props.logInUser.userType !== 3 && this.state.selectedUser.userType !== 1 && this.state.selectedUser.userType !== 2 && (<Tab
                    value="accountUsers"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    style={{alignItems:"flex-start", }}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <KeyboardArrowRightIcon color="currentColor" />
                        {this.props.translate("accountUser")}
                      </div>
                    }
                  />)}
                  {this.props.logInUser.userType !== 6  && (
                    <Tab
                       value="accountUnits"
                      classes={{
                        root: 'custom-tab-button2',  
                        selected: 'custom-tab-button-selected2'
                      }}
                      style={{alignItems:"flex-start", }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon color="currentColor" />
                          {this.props.translate("accountUnits")}
                        </div>
                      }
                    />
                  )}
                 {this.props.logInUser.userType && 
                  <Tab
                      value="waslApi"
                      classes={{
                        root: 'custom-tab-button2',
                        selected: 'custom-tab-button-selected2'
                      }}
                      style={{alignItems:"flex-start", }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon color="currentColor" />
                          {this.props.translate("waslApiKey")}
                        </div>
                      }
                    />}
              </Tabs>
              <Paper style={{ padding: 16, minHeight: 270, flex: 1 }} square={true}>
                {value === 'edit' && this.state.loader && (
                  <Fragment>
                    <div style={{ textAlign: 'center' }}>
                        <Loader defaultStyle />
                    </div>
                  </Fragment>
                )}
                {value === 'edit' && checkPrivileges('userUpdate') && this.state.selectedAccount && (
                  <Fragment>
                    <UpdateUserDetail
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      updateSelectedAccount={this.updateSelectedAccount}
                      {...this.props}
                      {...this.state}
                    />
                  </Fragment>
                )}
                {value === 'contractNumber' && checkPrivileges('userUpdate') && this.state.selectedAccount && (
                  <Fragment>
                    <ContractNumber
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      updateSelectedAccount={this.updateSelectedAccount}
                      {...this.props}
                      {...this.state}
                    />
                  </Fragment>
                )}
                {value === 'user' && (
                  <Fragment>
                    <User {...this.props} {...this.state} />
                  </Fragment>
                )}
                {value === 'unit' && (
                  <Fragment>
                    <Units {...this.props} {...this.state} />
                  </Fragment>
                )}
                {value === 'group' && (
                  <Fragment>
                    <Group {...this.props} {...this.state} />
                  </Fragment>
                )}
                {checkPrivileges('dashboard') && value === 'dashboard' && (
                  <Fragment>
                    <Dashboard {...this.props} {...this.state} />
                  </Fragment>
                )}
                {checkPrivileges('dashboard') && value === 'widget' && (
                  <Fragment>
                    <Widgets {...this.props} {...this.state} />
                  </Fragment>
                )}
                  {value === 'role' && (
                    <Fragment>
                      <Role {...this.props} {...this.state} />
                    </Fragment>
                  )}
                  {value === 'map' && (
                    <Fragment>
                      <Maps
                        {...this.props}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                      />
                    </Fragment>
                  )}
                  {value === 'changePassword' && (
                    <Fragment>
                      <ChangedPasword {...this.props} />
                    </Fragment>
                  )}
                  {value === 'report' && (
                    <Fragment>
                      <Reports {...this.props} />
                    </Fragment>
                  )}
                {value === 'themeSettings' && (
                  <Fragment>
                    <UpdateThemes
                      {...this.props}
                      {...this.state}
                      selectedAccount={this.state.selectedAccount&&this.state.selectedAccount}
                      UpdateSelectedItem={this.updateSelectedAccount}
                      hasAccessOfUpdate={checkPrivileges('accountUpdate')}
                    />
                  </Fragment>
                  )}   
                  {value === 'serverSettings' && (
                    <Fragment>
                       <Serversettings
                        updateOtherServerSetting={true}
                        otherServerSetting={this.state.selectedAccount&&this.state.selectedAccount}
                        updateSelectedAccount={this.UpdateSelectedItem}
                        translate={this.props.translate}
                        selectedItem={this.props.selectItemId}
                        userUpdate
                        // getMoreAccounts={this.props.getMoreAccounts}
                      />
                    </Fragment>
                  )}
                   {value === 'accountUsers' && (
                    <Fragment>
                      <AccountUser {...this.props} {...this.state} selectItemId={this.state.selectedAccount&&this.state.selectedAccount.id}/>
                    </Fragment>
                  )}
                  {value === 'accountUnits' && this.props.logInUser.userType !== 6 && (
                    <Fragment>
                      <AccountUnits {...this.props} {...this.state} selectItemId={this.state.selectedAccount&&this.state.selectedAccount.id}/>
                    </Fragment>
                  )}
                  {value === 'waslApi' && this.props.logInUser.userType && (
                    <Fragment>
                      <WaslApi
                        dispatch={this.props.dispatch}
                        location={this.props.location}
                        selectedItem={this.state.selectedAccount}
                        UpdateSelectedItem={this.updateSelectedAccount}
                      />
                    </Fragment>
                  )}
              </Paper>
             </AppBar>
          </div>
        </div>
        )
      // }
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loader defaultStyle />
        </div>
      )
    }
  }
}

viewUserDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    devices: state.devices,
    users: state.users,
    roles: state.roles,
    groups: state.groups,
    accounts: state.accounts,
    parentUserLimits: state.parentUserLimits,
    parentUnitLimits: state.parentUnitLimits,
    ServerSetting: state.ServerSetting,
    widget:state.widgets
  }
}
const mapStateToProps = connect(mapState)
export const ViewUserDetail = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(viewUserDetail))
)
