import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ViewUserDetail } from "./userDetail/viewUserDetail";
import { ViewStatistics } from "./statistics";
import { validEmailPattern } from "./../../Helpers";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import AddUserModal from "./addUserModal";
import MarkerMap from "../common/Maps";
import CustomDialog from "../common/Dialog";
import Moment from "moment";
import { parentUsersLimit, getUsers } from "../../Actions/Users";
import { parentUnitsLimit } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import Button from "../common/Button";
import withTranslationWrapper from "../../HOC/HocTranslate";
import "./usersModal.scss";
import {
  checkPrivileges,
  checkUserRoles,
  checkUserAcount,
  prepareUserForLogin,
  PaginationConfig,
  prepareUsers,
} from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import LoginAsUser from "../../Helpers/loginAsUser";
import Loader from "../../Layout/Loader";
import Style from "style-it";
import ExportFile from "./../common/ExportFile";
import { checkUnitsWithStandardType } from "./userDataTypes";
import TextField from "../common/TextField";
import { Grid } from "@mui/material";
import Menu from "../common/CommonMenu";
import { FormControlLabel } from "@mui/material";
import Checkbox from "../common/Checkbox";
import MenuItem from "../common/MenuItem";
import instance from "../../axios";
import SingleSelect from "../common/SingleSelect";
import withRoutes from "../../HOC/HocRoutes";

const userDataFormat = (data, extAttriubtes, itemId) => {
  if (data.roleId === "defaultRole") {
    data.roleId = "";
  }
  return {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
    deviceLimit: data.deviceLimit ? data.deviceLimit : "",
    deviceReadonly:
      data.deviceReadonly === "on" || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === "on" || data.disabled === true ? true : false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === "on" || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    // phone: data.phone,
    phone:
      Array.isArray(data.phone) && data.phone.length === 0
        ? ""
        : data.phone || "",
    poiLayer: data.poiLayer,
    readonly: data.readonly === "on" || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === "on" || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : 0,
    // mailLimit: data.mailLimit ? data.mailLimit : '',
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
    // roleId: data.roleId ? data.roleId : 2,
    userType: data.userType,
    accountId: data.accountId || 0,
    parentId: data.parentId || 0,
  };
};
const attributesDefaultValue = {
  distanceUnit: "km",
  speedUnit: "kmh",
  volumeUnit: "ltr",
  measurementStandard: "metric",
  currencies: "USD",
};
const formDefaultState = {
  name: "",
  accountName: "",
  email: "",
  accountEmail: "",
  password: "",
  confirmPassword: "",
  attributes: { ...attributesDefaultValue },
  phone: "",
  map: "",
  latitude: "",
  longitude: "",
  zoom: "",
  twelveHourFormat: "",
  coordinateFormat: "",
  poiLayer: "",
  disabled: "",
  admin: "",
  readonly: "",
  deviceReadonly: "",
  limitCommands: "",
  deviceLimit: 0,
  userLimit: 0,
  // mailLimit:0,
  token: "",
  reportInterval: 0,
  historyInterval: 0,
  expirationTime: null,
  roleId: "",
  userType: "",
  administrator: false,
  attributes: {},
  accountId: 0,
  parentId: 0,
};
let source;
class usersModal extends Component {
  constructor() {
    super();
    this.state = {
      ...PaginationConfig,
      selectedUser: "",
      addOption: false,
      defaultUserList: false,
      userDetailOption: false,
      isSelectedUser: false,
      localTime: new Date(),
      isVisableUserBtn: false,
      usesId: "",
      isMapModal: false,
      duplicateEntry: false,
      isVisableAddlocBtn: false,
      localPostionObj: "",
      selectedUsers: "",
      isVisableRoleAssiging: false,
      onDeleteConfirmation: false,
      emailPattern: false,
      tableData: [],
      defaultAccountsList: [],
      defaultUsersList: [],
      accountSelectedData: "",
      userSelectedData: "",
      pagData: "",
      isUserRecivedList: false,
      deviceLimitError: false,
      userLimitError: false,
      unitsLimitsMeaasge: "",
      usersLimitsMeaasge: "",
      parentUserLimits: "",
      parentUnitLimits: "",
      tableHeight: false,
      countChecked: 0,
      anchorEl: null,
      topRow: true,
      loadingUser: false,
      onChangeStatusConfirmation: false,
      isSubmit: false,
      loader: false,
      UserType: [
        { id: "1", label: "user" },
        { id: "2", label: "admin" },
        { id: "3", label: "Service Provider" },
        { id: "4", label: "Reseller" },
        { id: "5", label: "Distributor" },
      ],
      statusFilter: [],
      user: "",
      allUsers: [],

      // userStatus: [
      //   { id: '1', label: 'Online' },
      //   { id: '2', label: 'Offline' },
      //   { id: '3', label: 'Sleep' }
      // ],
      // userTime: [
      //   { id: '1', label: 'created time' },
      //   { id: '2', label: 'Active time' },
      //   { id: '3', label: 'Deleted time' },
      //   { id: '4', label: 'Edited time' }
      // ],
      // all fields of form
      form: {
        ...formDefaultState,
      },
      lengthCheck: false,
      upperCaseCheck: false,
      lowerCaseCheck: false,
      characterCheck: false,
      numberCheck: false,
      confirmPasswordCheck: false,
      firebaseProfiles: [],
    };
    this.au_submit = this.au_submit.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.modalControle = this.modalControle.bind(this);
    this.changeAttribute2 = this.changeAttribute2.bind(this);
    this.timeDateOnchange = this.timeDateOnchange.bind(this);
    this.userSelection = this.userSelection.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }

  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    // password
    // confirmPassword
    // Check each criteria
    const isLengthValid = this.state.form.password.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.password);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.password);
    const hasNumberValid = hasNumber.test(this.state.form.password);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.password);

    if (isLengthValid) {
      this.setState({ lengthCheck: true });
    } else {
      this.setState({ lengthCheck: false });
    }
    if (hasLowerCaseValid) {
      this.setState({ lowerCaseCheck: true });
    } else {
      this.setState({ lowerCaseCheck: false });
    }
    if (hasUpperCaseValid) {
      this.setState({ upperCaseCheck: true });
    } else {
      this.setState({ upperCaseCheck: false });
    }
    if (hasNumberValid) {
      this.setState({ numberCheck: true });
    } else {
      this.setState({ numberCheck: false });
    }
    if (hasSpecialCharValid) {
      this.setState({ characterCheck: true });
    } else {
      this.setState({ characterCheck: false });
    }
    if (this.state.form.password === this.state.form.confirmPassword) {
      this.setState({ confirmPasswordCheck: true });
    } else {
      this.setState({ confirmPasswordCheck: false });
    }

    const isPasswordValid =
      isLengthValid &&
      hasLowerCaseValid &&
      hasUpperCaseValid &&
      hasNumberValid &&
      hasSpecialCharValid;

    this.setState(
      {
        isValidPassword: isPasswordValid,
      },
      () => this.checkRequiredFields()
    );
  };

  componentWillMount() {
    this.getMoreUsers();
    this.getAllUsers();
  }
  componentWillUnmount() {
    // if (source) {
    //   source.cancel()
    // }
    // this.props.dispatch(Notifications.removeAll())
  }

  addUser = () => {
    this.checkLimitsForCreatingUser(this.props.logInUser.id);
    let limitsOptionData = formDefaultState;
    let defaulfFeildsSetting = this.checkAttributes(this.props.logInUser);
    // limitsOptionData.deviceLimit = this.props.parentUnitLimits.remaningLimit
    // limitsOptionData.userLimit = this.props.parentUserLimits.remaningLimit
    // if (limitsOptionData.userLimit > 0) {
    //   limitsOptionData.userLimit = limitsOptionData.userLimit - 1
    // }
    if (this.props.logInUser.userType === -1) {
      // its just for owner user enable when other user implements
      this.fetchAccountsList(this.props.logInUser.accountId);
      this.fetchUsersList(this.props.logInUser.accountId);
    }
    this.setState({
      selectedUser: "",
      addOption: true,
      defaultUserList: false,
      noRecord: false,
      topRow: false,
      form: {
        ...limitsOptionData,
        accountId: this.props.logInUser.accountId,
        parentId: this.props.logInUser.id,
        ...defaulfFeildsSetting,
      },
      // accountSelectedData: {
      //   id: this.props.ServerSetting.id,
      //   label: this.props.ServerSetting.name,
      //   value: this.props.ServerSetting.id
      // },
      // userSelectedData: {
      //   id: this.props.logInUser.id,
      //   label: this.props.logInUser.name,
      //   value: this.props.logInUser.id
      // }
    });
  };
  fetchAccountsList = (id) => {
    let fetchApi = `/api/accounts/all?accountId=${id}&limit=100`;
    if (this.props.logInUser.userType === -1) {
      fetchApi = `/api/accounts/all?accountId=${id}&all=true&limit=100`;
    }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result =
                userlist.data &&
                userlist.data.map((item) => ({
                  ...item,
                  label: item.label || item.name,
                  value: item.id,
                }));
              result.unshift({
                id: this.props.ServerSetting.id,
                label: this.props.ServerSetting.name,
                value: this.props.ServerSetting.id,
              });
              this.setState({
                defaultAccountsList: result,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(err[1]));
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        //   this.props.dispatch(notifyError({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };
  fetchUsersList = (id) => {
    let fetchApi = `/api/users/list?accountId=${id}&limit=100`;
    // if (this.props.logInUser.userType === -1) {
    //   fetchApi = `/api/users/list?${onBase}=${id}&all=true&limit=100`
    // }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result = [];
              userlist.data &&
                userlist.data.map((item) => {
                  if (item.userType !== 1) {
                    result.push({
                      label: item.label || item.name,
                      value: item.id,
                    });
                  }
                });
              if (parseInt(id) === parseInt(this.props.ServerSetting.id)) {
                result.unshift({
                  id: this.props.logInUser.id,
                  label: this.props.logInUser.name,
                  value: this.props.logInUser.id,
                });
              }
              if (result.length === 0) {
                this.props.dispatch(
                  notifyError(
                    "selectedAccountHaveNoUserWhichIsAbleToCreateNewUser"
                  )
                );
              }
              this.setState({
                defaultUsersList: result,
                isUserRecivedList: true,
                loadingUser: false,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(err[1]));
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        // this.props.dispatch(notifyError({
        // message: 'somethingWentWrong',
        // autoDismiss: 5
        // }))
      });
  };
  checkAttributes = (data) => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: 25.2048,
      longitude: 55.2708,
      zoom: 16,
      map: data.map,
      attributes: {
        ["web.liveRouteLength"]:
          data.attributes && data.attributes["web.liveRouteLength"],
        ["web.maxZoom"]: data.attributes && data.attributes["web.maxZoom"],
        ["web.selectZoom"]:
          data.attributes && data.attributes["web.selectZoom"],
        ["timezone"]: data.attributes && data.attributes["timezone"],
        ["distanceUnit"]: data.attributes && data.attributes["distanceUnit"],
        ["speedUnit"]: data.attributes && data.attributes["speedUnit"],
        ["volumeUnit"]: data.attributes && data.attributes["volumeUnit"],
        ["measurementStandard"]:
          data.attributes && data.attributes["measurementStandard"],
      },
    };
    return form;
  };

  removeUser(selected) {
    if (selected && selected.length) {
      this.setState({
        onDeleteConfirmation: true,
        selectedUser: selected[0],
        form: "",
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        directDelete: false,
        selectedUser: selected,
        form: "",
      });
    }
  }
  au_submit() {
    let obj = {};
    let url = `/api/accounts`;
    let { attributes } = this.state.form;
    let {
      name,
      email,
      password,
      reportInterval,
      historyInterval,
      parentId,
      accountEmail,
      accountName,
      accountDescription,
      accountContact,
    } = this.state.form;
    if (
      name.trim().length > 0 &&
      email.trim().length > 0 &&
      password.trim().length > 0
    ) {
      this.setState({
        selectedUser: "",
      });
      if (parentId) {
        attributes = { ...attributes, parentId: parentId };
      }

      const obj1 = userDataFormat(this.state.form, attributes);
      if (this.state.form.userType > 2) {
        obj = {
          account: {
            name: accountName,
            email: accountEmail,
            description: accountDescription ? accountDescription : "",
            contact: accountContact ? accountContact : "",
            reportInterval: reportInterval,
            historyInterval: historyInterval,
            attributes: { ...this.state.form.attributes },
          },
          user: {
            ...obj1,
          },
        };
      } else {
        obj = { ...obj1 };
        url = `/api/users/`;
      }

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      })
        .then((response) => {
          // if (response.ok) {
          response.json().then((user) => {
            if (user.status === "success") {
              this.getMoreUsers();
              if (this.props.onCloseModal) {
                this.props.onCloseModal();
              }
              // this.getUsersLimits()
              this.props.dispatch(notifySuccess("userIsCreated"));
              this.setState({
                addOption: false,
                selectedUser: "",
                isVisableUserBtn: false,
                defaultUserList: true,
                topRow: true,
              });
            } else if (user?.statusCode === "440") {
              window.location.replace("/login");
            }
            else if (user.statusCode) {
              let errorMessage = user?.message;
          
              if (errorMessage.includes("Duplicate entry")) {
                  this.props.dispatch(notifyError("Email already registered. Please use another one."));
              } else {
                  try {
                      var err = errorMessage.split(":");
                      var err2 = err[1].split("&#");
                      this.props.dispatch(notifyError(err2[0]))
                  } catch (e) {
                      this.props.dispatch(notifyError("Something went wrong. Please try again."));
                  }
              }
          }          
           else {
              throw response;
            }
          });
          // }
          // else {
          //   throw response
          // }
        })
        .catch((e) => {
            this.props.dispatch(notifyError("somthingWentWrongMessage"));
          // if(e && e.text){
          //   e.text().then(err => {
          //     if (err.includes('Duplicate entry')) {
          //       this.setState({
          //         duplicateEntry: true,
          //         isVisableUserBtn: false,
          //         topRow:true,

          //       })
          //       this.props.dispatch(
          //         notifyError({
          //           message: this.props.translate('userEmailIsAllreadyInUsed'),
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     } else {
          //       let a = err.split('- SecurityException').splice(0, 1)
          //       this.props.dispatch(
          //         notifyError({
          //           message: a[0],
          //           autoDismiss: 5
          //         })
          //       )
          //     }
          //   })
          // } else {
          //   this.props.dispatch(
          //     notifyError({
          //       message: this.props.translate('somthingWentWrongMessage'),
          //       autoDismiss: 5,
          //       location: this.props.location
          //     })
          //   )
          // }
        });
    } else {
      this.setState({
        isVisableUserBtn: false,
      });
      this.props.dispatch(notifyError("emptyFields"));
    }
  }
  deleteUser = () => {
    let { selectedUser } = this.state;
    if (selectedUser.id) {
      fetch(`/api/users/${selectedUser.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...selectedUser,
        }),
      })
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              this.getMoreUsers();
              this.props.dispatch(notifySuccess("userIsRemoved"));
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(err[1]));
            } else {
              throw response;
            }
          });
        })

        .catch((e) => {
          // if(e && e.text){
          // e.text().then(err => {
          // var array = err.split("-")
          // if(array.length > 1){
          //       this.props.dispatch(
          //         notifyError({
          //           message: this.props.translate(array[0]),
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          // }
          // else {
          this.props.dispatch(notifyError("somthingWentWrongMessage"));
          // }
          //   })
          // }
        });
      this.setState({
        selectedUser: "",
        onDeleteConfirmation: false,
        userDetailOption: false,
        defaultUserList: true,
      });
    }
  };

  //refreshing users and units limits
  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
        .then((response) => {
          if (response.ok) {
            response.json().then((unitsLimits) => {
              if (unitsLimits.status === "success") {
                this.props.dispatch(parentUnitsLimit(unitsLimits));
              } else if (unitsLimits?.statusCode === "440") {
                window.location.replace("/login");
              } else if (unitsLimits.statusCode) {
                var err = unitsLimits?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(notifyError(err[1]));
              }
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(notifyError("somethingWentWrong"));
        });
      //users limits
      fetch(`/api/users/count?userId=${this.props.logInUser.id}`)
        .then((response) => {
          if (response.ok) {
            response.json().then((usersLimits) => {
              if (usersLimits.status === "success") {
                this.props.dispatch(parentUsersLimit(usersLimits));
              } else if (usersLimits?.statusCode === "440") {
                window.location.replace("/login");
              } else if (usersLimits.statusCode) {
                var err = usersLimits?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(notifyError(err[1]));
              }
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(notifyError("somethingWentWrong"));
        });
    }
  };
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (name === "measurementStandard") {
      let data = checkUnitsWithStandardType(value);
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === "number" ? parseInt(value) : value,
              distanceUnit: data[0].distanceUnit.key,
              speedUnit: data[0].speedUnit.key,
              volumeUnit: data[0].volumeUnit.key,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: name === "mailLimit" ? parseInt(value) : value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  timezone_handleChange = (name, value) => {
    if (name === "timezone") {
      if (!value || value.value === "None") {
        delete this.state.form.attributes[name];
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: value.value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (name === "accountId") {
        this.setState(
          {
            loadingUser: true,
            form: {
              ...this.state.form,
              [name]: value?.value || {},
              userId: "",
              deviceLimit: 0,
              userLimit: 0,
              // mailLimit:0,
            },
            accountSelectedData: value,
            userSelectedData: "",
            isUserRecivedList: false,
            deviceLimitError: false,
            userLimitError: false,
            usersLimitsMeaasge: "",
          },
          () => {
            this.fetchUsersList(value.value);
            this.checkRequiredFields();
          }
        );
      } else if (name === "parentId") {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
              deviceLimit: 0,
              userLimit: 0,
              // mailLimit:0,
            },
            userSelectedData: value,
            deviceLimitError: false,
            userLimitError: false,
            usersLimitsMeaasge: "",
          },
          () => {
            if (value.value) {
              this.checkLimitsForCreatingUser(value.value);
            }
            this.checkRequiredFields();
          }
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value?.value || "",
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };
  modalControle() {
    this.setState({
      addOption: false,
      duplicateEntry: false,
      topRow: true,
      userDetailOption: false,
      passwordOption: false,
      isSelectedUser: false,
      selectedUser: "",
      form: "",
      isVisableUserBtn: false,
      isVisableRoleAssiging: false,
    });
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
    if (this.state.noRecord) {
      this.setState({
        defaultUserList: false,
        topRow: true,
      });
    } else {
      this.setState({
        defaultUserList: true,
        topRow: true,
      });
    }
  }

  userSelection(item) {
    if (item) {
      this.setState(
        {
          defaultUserList: false,
          selectedUser: item,
          userDetailOption: true,
          topRow: false,
          isSelectedUser: true,
          addOption: false,
          form: { ...item },
        },
        () => {
          if (item.userType > 2) {
            this.getFireBaseProfiles();
          }
        }
      );
    }
  }

  copyTokenToClipboard = () => {
    const { form } = this.state;
    const token = form.token;

    // Create a temporary textarea element to copy the token to clipboard
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = token;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);

    // Show a notification indicating that the token has been copied to clipboard
    // this.props.enqueueSnackbar('Token copied to clipboard', {
    //   variant: 'success',
    //   autoHideDuration: 2000,
    // });
  };
  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  timeDateOnchange(date) {
    this.setState((preState) => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date,
      },
    }));
  }
  setNoToken = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          token: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = (name) => (event) => {
    if (!event) {
      // Handle the case where event is null (e.g., when clearing the input)
      this.setState({
        [name]: null,
      });
      return;
    }
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "reportInterval" || name === "historyInterval") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: parseInt(target.value),
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "password" || name === "confirmPassword") {
      let value = target.value;
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.verifyPassword()
      );
    } else {
      let value = target.value;
      if (value) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        // if (name === 'mailLimit') {

        // }
        if (name === "deviceLimit") {
          const { parentUnitsLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) ===
                -1
              ) {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value) >
                parseInt(
                  parentUnitsLimit.remaningLimit - parentUnitsLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "userLimit") {
          const { parentUsersLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUsersLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(parentUsersLimit.remaningLimit) === -1) {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value + 1) >
                parseInt(
                  parentUsersLimit.remaningLimit - parentUsersLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => {
            this.checkRequiredFields();
            if (
              name === "userType" &&
              this.props?.logInUser?.userType === -1 &&
              (value === 3 || value === 4 || value === 5 || value === 6)
            ) {
              this.getFireBaseProfiles();
            }
          }
        );
      } else if (this.state.form[name]) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  getFireBaseProfiles = () => {
    instance({
      url: `/api/notifications/notificators/firebase-profiles`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        //  console.log("Res=====", response);
        const firebaseProfiles = response.map((profile) => {
          //  console.log("profile=====", profile);
          return {
            key: profile, // Ensure 'key' or 'id' exists
            name: profile || "",
          }; // Ensure 'name' exists
        });
        this.setState({ firebaseProfiles });
      })
      .catch((error) => {
        console.log("error ===", error);
      });
  };

  checkLimitsForCreatingUser = (id) => {
    this.setState(
      {
        isUserRecivedList: false,
      },
      () => {
        fetch(`/api/devices/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((unitsLimits) => {
                if (unitsLimits.status === "success") {
                  this.setState({
                    parentUnitsLimit: unitsLimits.data,
                  });
                } else if (unitsLimits?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (unitsLimits.statusCode) {
                  var err = unitsLimits?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(notifyError(err[1]));
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            this.props.dispatch(notifyError("somethingWentWrong"));
          });
        //users limits
        fetch(`/api/users/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  let usersLimits = res.data;
                  this.setState({
                    parentUsersLimit: usersLimits,
                    isUserRecivedList: true,
                  });
                  if (usersLimits.remaningLimit === 0) {
                    this.setState({
                      userLimitError: true,
                      usersLimitsMeaasge: "userLimitErrorParent1",
                    });
                    this.props.dispatch(notifyError("userLimitErrorParent2"));
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(notifyError(err[1]));
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            this.props.dispatch(notifyError("somethingWentWrong"));
          });
      }
    );
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields() {
    let valid = true;
    let {
      name,
      email,
      password,
      confirmPassword,
      userType,
      accountId,
      parentId,
      zoom,
    } = this.state.form;
    if (
      (name &&
        email &&
        accountId &&
        parentId &&
        password &&
        confirmPassword &&
        password === confirmPassword &&
        zoom,
      userType)
    ) {
      if (!validEmailPattern.test(email)) {
        valid = false;
      }
      // check both user and device limit error
      let limitError = true;
      if (!this.state.deviceLimitError && !this.state.userLimitError) {
        limitError = false;
      }
      if (zoom > 2 && zoom < 19) {
        this.setState({
          isVisableUserBtn: limitError ? false : valid,
          duplicateEntry: !valid,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
      // this.setState(
      //   {
      //     isVisableUserBtn: limitError ? false : valid,
      //     duplicateEntry: !valid
      //   })
    } else {
      this.setState({
        isVisableUserBtn: false,
        duplicateEntry: false,
      });
    }
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true,
      });
    }
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };

  onCloseC_Modal = () => {
    this.setState({
      isVisableRoleAssiging: false,
      userDetailOption: false,
      showStatisticsModel: false,
      defaultUserList: true,
      topRow: true,
    });
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
      onChangeStatusConfirmation: false,
      statusMode: false,
      isSubmit: false,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  loginAsUser = (data) => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false,
        },
        () => {
          this.setState(
            {
              isUserLogin: true,
              userDetailOption: false,
            },
            () => this.getMoreUsers()
          );
        }
      );
    }
  };
  showStatistics = (data) => {
    if (data[0]) {
      this.setState({
        defaultUserList: false,
        selectedUser: data[0],
        Loader: false,
        topRow: false,
        showStatisticsModel: true,
      });
    }
  };
  prepareUserDataForTable = () => {
    let apiCall = `/api/accounts/list?userId=${this.props.logInUser.id}&limit=-1&all=true`;
    if (this.props.logInUser.userType === 6) {
      apiCall = `/api/accounts/list?userId=${this.props.logInUser.id}&limit=-1`;
    }
    fetch(apiCall)
      .then((response) => {
        if (response.ok) {
          response.json().then((accounts) => {
            if (accounts.status === "success") {
              let prData = prepareUserForLogin(
                checkUserAcount(
                  checkUserRoles(this.props.users.data, this.props.roles),
                  accounts.data
                )
              );
              this.setState({
                tableData: prData,
              });
            } else if (accounts?.statusCode === "440") {
              window.location.replace("/login");
            } else if (accounts.statusCode) {
              var err = accounts?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(err[1]));
            }
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {
        this.props.dispatch(notifyError("somethingWentWrong"));
      });
  };
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        //tableData: [],
        pageSize: value,
        //defaultUserList: false
      },
      () => this.getMoreUsers()
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultUserList: false
      },
      () => this.getMoreUsers()
    );
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
        loader: true,
        defaultUserList: false,
      },
      () => this.getMoreUsers()
    );
  };

  handleUsersChange = (name, e) => {
    if (e.length) {
      this.setState({
        tableHeight: true,
      });
    } else {
      this.setState({
        tableHeight: false,
      });
    }
    if (name === "type") {
      let users = "";
      e.map((i) => {
        users += "&userType=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userTypes: users,
        },
        () => this.getMoreUsers()
      );
    } else if (name === "status") {
      let usersStatus = "";
      e.map((i) => {
        usersStatus += "&userStatus=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userStatuses: usersStatus,
        },
        () => this.getMoreUsers()
      );
    } else if (name === "time") {
      let usersTime = "";
      e.map((i) => {
        usersTime += "&usersTime=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userTimes: usersTime,
        },
        () => this.getMoreUsers()
      );
    }
  };
  callUserFunc = () => {
    if (this.state.itemSearch) {
      this.getMoreUsers();
    } else {
      return null;
    }
  };
  loginAsUserhandleChange = (id, value) => {
    let { tableData } = this.state;
    tableData.map((item) => {
      if (item.id === id) {
        item.logInAsUserState = value;
      }
    });
    this.setState({
      tableData,
    });
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = (user) => {
    if (this.state.tableData.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };
  openFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  };
  handleClickFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseFilter = () => {
    this.setState({ anchorEl: null });
  };
  handleChangeFilter = (name, event) => {
    let users = "";
    this.setState(
      {
        userTypes: [],
        statusFilter: {
          ...this.state.statusFilter,
          [name]: event.target.checked,
        },
        countChecked: 0,
      },
      () => {
        Object.entries(this.state.statusFilter).map(([key, value]) => {
          if (value) {
            users += "&userType=" + key;
            this.setState({
              countChecked: ++this.state.countChecked,
            });
          }
        });
        this.setState(
          {
            page: 1,
            userTypes: users,
          },
          () => {
            this.getMoreUsers();
          }
        );
      }
    );
  };
  onBinaryView = () => {
    this.props.navigate("/users/view");
  };

  ChangeStatusSubmit = () => {
    this.setState({ isSubmit: false }, () => {
      if (this.state.selectedUser.disabled) {
        let msg = this.state.statusMode
          ? "allChildEnabledSuccess"
          : "userEnabled";
        fetch(
          `api/users/enable?userId=${
            this.state.selectedUser.id
          }&enableAllChild=${this.state?.statusMode ? true : false}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            // if(response.status === 200){
            response.json().then((res) => {
              if (res.status === "success") {
                this.setState(
                  {
                    onChangeStatusConfirmation: false,
                    statusMode: false,
                    isSubmit: true,
                    selectedUser: {
                      ...this.state.selectedUser,
                      disabled: false,
                    },
                  },
                  () => {
                    this.getMoreUsers();
                  }
                );
                this.props.dispatch(notifySuccess(msg));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(notifyError(err[1]));
              }
            });
            // }
          })
          .catch((e) => {
            console.log("e =", e);
            // this.props.dispatch(notifyError({
            //   message: 'somethingWentWrong',
            //   autoDismiss: 5
            // }))
          });
      } else {
        let msg = this.state.statusMode
          ? "allChildDisabledSuccess"
          : "userDisabled";
        fetch(
          `api/users/disable?userId=${
            this.state.selectedUser.id
          }&disableAllChild=${this.state?.statusMode ? true : false}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            // if(response.status === 200){
            response.json().then((res) => {
              if (res.status === "success") {
                this.setState(
                  {
                    onChangeStatusConfirmation: false,
                    statusMode: false,
                    // isSubmit:false,
                    selectedUser: {
                      ...this.state.selectedUser,
                      disabled: true,
                    },
                  },
                  () => {
                    this.getMoreUsers();
                  }
                );
                this.props.dispatch(notifySuccess(msg));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(notifyError(this.props.translate(err[1])));
              }
            });
            // }
          })
          .catch((e) => {
            console.log("e =", e);
            // this.props.dispatch(notifyError({
            // message: 'somethingWentWrong',
            // autoDismiss: 5
            // }))
          });
      }

      this.setState({
        statusDialog: false,
      });
    });
  };

  onChangeStatus = (selected) => {
    this.setState({
      onChangeStatusConfirmation: true,
      selectedUser: selected[0],
      isSubmit: true,
    });
  };

  changeStatusMode = () => {
    this.setState({ statusMode: !this.state.statusMode });
  };
  getMoreUsers = (id) => {
    let { page, pageSize, itemSearch, userTypes, userStatuses, userTimes } =
      this.state;
    if (this.props.logInUser.id) {
      let userId = id
        ? id
        : this.state.user.id
        ? this.state.user.id
        : this.props.logInUser.id;
      let apiCall = `/api/users/get?userId=${userId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}${
        userTypes || ""
      }${userStatuses || ""}${userTimes || ""}`;
      source = axios.CancelToken.source();
      axios
        .get(apiCall, {
          cancelToken: source.token,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === "success") {
            if (res.data.data.length === 0) {
              this.setState({
                noRecord: true,
              });
            } else {
              this.setState({
                noRecord: false,
              });
            }
            if (res.data.data) {
              let udata = prepareUserForLogin(
                checkUserRoles(prepareUsers(res.data.data), this.props.roles)
              );
              this.props.dispatch(getUsers(udata));

              let users = udata.map((u) => {
                if (u.created) {
                  return {
                    ...u,
                    created: this.props.logInUser.twelveHourFormat
                      ? Moment(u.created).format("YYYY-MM-DD hh:mm A")
                      : Moment(u.created).format("YYYY-MM-DD HH:mm"),
                  };
                } else {
                  return { ...u };
                }
              });

              this.setState({
                tableData: users,
                defaultUserList: true,
                loader: false,
                pagData: response.data.data,
              });
            } else {
              this.setState({
                tableData: [],
                defaultUserList: true,
                pagData: "",
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            this.props.dispatch(notifyError(err[1]));
          }
        })
        .catch((e) => {
          //   this.props.dispatch(notifyError({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        });
    }
  };

  // getAllUsers = (input) => {
  //   console.log("===id",input);
  //   let {
  //     page,
  //     pageSize,
  //     itemSearch,
  //     userTypes,
  //     userStatuses,
  //     userTimes
  //   } = this.state
  //   let inputSearch= input? input : itemSearch
  //   if (this.props.logInUser.id) {
  //     // let userId = id ? id : this.props.logInUser.id ;
  //     let apiCall = `/api/users/get?userId=${
  //       this.props.logInUser.id
  //     }&all=true&limit=${-1}&search=${inputSearch}${userTypes ||
  //       ''}${userStatuses || ''}${userTimes || ''}`

  //     source = axios.CancelToken.source()
  //     axios
  //       .get(apiCall, {
  //         cancelToken: source.token
  //       })
  //       .then(response => {
  //         let res = response.data
  //         if (res.status === 'success') {
  //           if(res.data.data.length === 0){
  //                 this.setState({
  //                   noRecord: true,
  //                 })
  //           }
  //           else{
  //             this.setState({
  //               noRecord: false,
  //             })
  //           }
  //           if (res.data.data) {
  //             let udata = prepareUserForLogin(
  //               checkUserRoles(
  //                 prepareUsers(res.data.data),
  //                 this.props.roles
  //               )
  //             )
  //             this.props.dispatch(getUsers(udata))

  //             let users = udata.map(u => {
  //               if (u.created) {
  //                 return {
  //                   ...u,
  //                   created: this.props.logInUser.twelveHourFormat
  //                     ? Moment(u.created).format('YYYY-MM-DD hh:mm A')
  //                     : Moment(u.created).format('YYYY-MM-DD HH:mm')
  //                 }
  //               } else {
  //                 return { ...u }
  //               }
  //             })

  //             this.setState({
  //               allUsers: users,
  //               defaultUserList: true,
  //               loader:false,
  //               pagData: response.data.data
  //             })
  //           } else {
  //             this.setState({
  //               allUsers: [],
  //               defaultUserList: true,
  //               pagData: ''
  //             })
  //           }
  //         }
  //         else if(res?.statusCode === '440'){
  //           window.location.replace('/login')
  //         }
  //         else if(res.statusCode){
  //           var err = res?.message.split(':')
  //           err[1] =err[1].replace(')', "")
  //           this.props.dispatch(
  //             notifyError({
  //               message: this.props.translate(err[1]),
  //               autoDismiss: 5,
  //               location: this.props.location
  //             })
  //           )
  //         }
  //       })
  //       .catch(e => {
  //       //   this.props.dispatch(notifyError({
  //       //   message: 'somethingWentWrong',
  //       //   autoDismiss: 5
  //       // }))
  //     })
  //   }
  // }

  getAllUsers = (input) => {
    fetch(`/api/users/list?userId=${this.props.logInUser.id}&limit=100`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let data = res.data;
              let result = data.data.map((item) => ({
                ...item,
                label: item.label || item.name,
                value: item.id,
              }));
              if (result.length === 0) {
                this.setState({
                  resultMessage: "No option found",
                  allUsers: "",
                });
              } else {
                this.setState({
                  resultMessage: "",
                  allUsers: result,
                });
              }
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(err[1]));
            }
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {});
  };

  searchList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let json = await response.json();
    let result =
      (await json.data) &&
      json.data.data &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };

  handleChangeUser = (name, value) => {
    const userId = value && value.id;
    this.setState({ user: value }, () => {
      this.getMoreUsers(userId);
    });
  };

  render() {
    const { logInUser, users, roles } = this.props;

    return (
      <div style={{ position: "relative" }}>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>
        {this.state.addOption && logInUser && (
          <>
            <AddUserModal
              {...this.state}
              roleTypes={
                this.props.roles &&
                this.props.roles.filter((item) => item.id > 7)
              }
              logInUser={logInUser}
              formSubmit={this.au_submit}
              timeDateOnchange={this.timeDateOnchange}
              generateToken={this.generateToken}
              copyTokenToClipboard={this.copyTokenToClipboard}
              modalControle={this.modalControle}
              changeAttribute2={this.changeAttribute2}
              handleChange={this.handleChange}
              buttonText={this.props.translate("sharedCreate")}
              handleClickShowPostion={this.handleClickShowPostion}
              userType={logInUser.userType}
              setExpirationTime={this.setExpirationTime}
              timezone_handleChange={this.timezone_handleChange}
              setNoToken={this.setNoToken}
              usersLimit={
                this.props.parentUserLimits.remaningLimit -
                this.props.parentUserLimits.totalCount
              }
              deviceLimit={
                this.props.parentUnitLimits.remaningLimit -
                this.props.parentUnitLimits.totalCount
              }
              hasAccessOfUpdate={checkPrivileges("userUpdate")}
              hasAccessOfDelete={checkPrivileges("userDelete")}
              hasAccessOfCreate={checkPrivileges("userCreate")}
            />
            {/* // ) : ( */}
            {/* //   <Loader /> */}
            {/* // )} */}
          </>
        )}
        {this.state.selectedUser && this.state.userDetailOption && (
          <ViewUserDetail
            firebaseProfiles={this.state.firebaseProfiles}
            selectItemName={this.state.selectedUser.name}
            selectItemId={this.state.selectedUser}
            onClose={this.onCloseC_Modal}
            usersLimit={this.state.selectedUser.userLimit}
            mailLimit={this.state.selectedUser.attributes.mailLimit}
            deviceLimit={this.state.selectedUser.deviceLimit}
          />
        )}
        {this.state.selectedUser && this.state.showStatisticsModel && (
          <ViewStatistics
            selectItem={this.state.selectedUser}
            onClose={this.onCloseC_Modal}
            title="Users"
          />
        )}

        {this.state.topRow && !this.state.addOption && (
          <>
            <Grid container className="breadcrumb-row">
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>{this.props.translate("users")}</h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName="user"
                    isClearable
                    isDisabled={
                      this.state.allUsers.length <= 0 ? "isDisabled" : ""
                    }
                    defaultOptions={this.state.allUsers || []}
                    loadOptions={this.searchList}
                    label={this.props.translate("selectUser")}
                    // value={this.state.user  || ''}
                    value={
                      this.state.user.id
                        ? {
                            key: this.state.user.id,
                            id: this.state.user.id,
                            label: this.state.user.label,
                          }
                        : ""
                    }
                    handleChange={this.handleChangeUser}
                    canAssign
                    placeholder="user"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.state.itemSearch}
                    onChange={(e) => this.SearchItem(e.target.value)}
                  />
                </div>
                {this.state.openFilter && (
                  <div style={{ width: "20%", marginRight: 20 }}>
                    <Filter
                      countChecked={this.state.countChecked}
                      anchorEl={this.state.anchorEl}
                      handleChangeFilter={this.handleChangeFilter}
                      handleClickFilter={this.handleClickFilter}
                      handleCloseFilter={this.handleCloseFilter}
                      statusFilter={this.state.statusFilter}
                      userType={this.props.logInUser.userType}
                    />
                  </div>
                )}
                {!this.state.openFilter && (
                  <Button
                    disabled={
                      this.state.tableData && this.state.tableData.length === 0
                    }
                    aria-label="filter"
                    size="small"
                    color="inherit"
                    onClick={this.openFilter}
                    style={{ marginRight: "20px" }}
                  >
                    {this.props.translate("filter")}
                  </Button>
                )}
                <ExportFile
                  title={this.props.translate("export")}
                  downloadType="users"
                  translate={this.props.translate}
                  dataLength={
                    this.props.users && this.props.users.length ? true : false
                  }
                  closedModel={this.onCloseC_Modal}
                  dispatch={this.props.dispatch}
                />
                <Button
                  disabled={
                    !(checkPrivileges("userCreate") &&
                    (this.props.parentUserLimits.remaningLimit > 0 ||
                      this.props.parentUserLimits.remaningLimit === -1)
                      ? true
                      : false)
                  }
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={this.addUser}
                  id="createButton"
                >
                  {this.props.translate("create")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {this.state.defaultUserList ? (
          <>
            <Table
              rows={this.state.tableData || []}
              pagination={this.setPagination(this.state.pagData)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onClick={this.userSelection}
              onDelete={this.removeUser}
              loginAsUser={this.loginAsUser}
              showStatistics={this.showStatistics}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              userDataArray1={this.state.UserType}
              userDataArrayChange1={(e) => this.handleUsersChange("type", e)}
              ServerSetting={this.props.ServerSetting}
              btnName={this.props.translate("create")}
              onCreate={() => this.addUser()}
              btnName1="users"
              onBinaryView={() => this.onBinaryView()}
              logInUser={this.props.logInUser}
              btnView={
                this.props.logInUser.userType === -1
                  ? this.props.translate("view")
                  : ""
              }
              viewButton={this.props.logInUser.userType === -1 ? true : false}
              isEditable={true}
              themecolors={this.props.themecolors}
              isRowIcons={true}
              isCursoPointer
              filterDropdown
              // setSerialNo
              hasAccessOfChangeStatus={
                this.props.logInUser.userType === -1 ? true : false
              }
              onChangeStatus={this.onChangeStatus}
              hasAccessOfDelete={checkPrivileges("userDelete")}
              rowDefinition={rowDefinitionR(this.props.translate)}
            />
          </>
        ) : (
          !this.state.addOption &&
          !this.state.showStatisticsModel &&
          !this.state.userDetailOption &&
          !this.state.noRecord && <Loader />
        )}
        {/* // ) : (
        //   (!this.state.addOption && !this.state.showStatisticsModel && !this.state.userDetailOption && !this.state.noRecord) 
        //   ? <Loader /> 
        //   : this.state.noRecord
        //   ? <h1>No records found</h1>
        //   : ''
        // ) */}
        {this.state.loader && <Loader />}
        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            CancelText={this.props.translate("sharedBack")}
            fullWidth
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedUser.zoom || 0}
              lat={this.state.selectedUser.latitude || 0}
              lng={this.state.selectedUser.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteUser}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedUser.name}
          />
        )}
        {this.state.onChangeStatusConfirmation && (
          <CustomDialog
            title="Change Status"
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            hideDragIcon
            fullWidth
            headerActions={
              <Button
                onClick={() => this.ChangeStatusSubmit()}
                disabled={!this.state.isSubmit}
              >
                {this.props.translate("Submit")}
              </Button>
            }
            isVisableBtn
            noFullScreen
          >
            {this.state.selectedUser.disabled ? (
              <>
                <Checkbox
                  name="DocCheckbox"
                  color="primary"
                  checked={this.state?.statusMode || ""}
                  value={true}
                  onChange={(e) => this.changeStatusMode(e)}
                  label={this.props.translate("allChildEnable")}
                />
              </>
            ) : (
              <>
                <Checkbox
                  name="DocCheckbox"
                  color="primary"
                  checked={this.state?.statusMode || ""}
                  value={true}
                  onChange={(e) => this.changeStatusMode(e)}
                  label={this.props.translate("allChildDisable")}
                />
              </>
            )}
          </CustomDialog>
        )}
      </div>
    );
  }
}

{
  /* <RadioGroup
              aria-label='selectedDate'
              name='selectedDate'
              value={this.state.statusMode}
              onChange={e => this.changeStatusMode(e)}
              row
            >
              
              {this.state.selectedUser.disabled ? (
                <FormControlLabel
                  classes={{
                    label: 'form-contol-label'
                  }}
                  value={'enable'}
                  control={<Radio size='small' />}
                  label={this.props.translate('allChildEnable')}
                  labelPlacement='end'
                />
                ):(<FormControlLabel
                  classes={{
                    label: 'form-contol-label'
                  }}
                  value={'disable'}
                  control={<Radio size='small' />}
                  label={this.props.translate('allChildDisable')}
                  labelPlacement='end'
                />)}
            </RadioGroup> */
}

const mapState = (state) => {
  return {
    users: state.users,
    logInUser: state.logInUsers,
    roles: state.roles,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    devices: state.devices,
    groups: state.groups,
    accounts: state.accounts,
    parentUserLimits: state.parentUserLimits,
    parentUnitLimits: state.parentUnitLimits,
  };
};
const mapStateToProps = connect(mapState);
export const UsersList = mapStateToProps(
  withRoutes(withTranslationWrapper(usersModal))
);

const Filter = (props) => {
  const html = (
    <div>
      <a
        className="v-filter-field"
        href={null}
        aria-owns={props.anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={props.handleClickFilter}
      >
        {props.countChecked} Selected
      </a>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleCloseFilter}
      >
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="user"
              checked={props.statusFilter[1]}
              onChange={(e) => props.handleChangeFilter("1", e)}
              canAssign
              canRemove
            />
          }
          label="User"
        />
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="admin"
              checked={props.statusFilter[2]}
              onChange={(e) => props.handleChangeFilter("2", e)}
              canAssign
              canRemove
            />
          }
          label="Admin"
        />
        {(props.userType > 3 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="adminAccount"
                checked={props.statusFilter[3]}
                onChange={(e) => props.handleChangeFilter("3", e)}
                canAssign
                canRemove
              />
            }
            label="Admin Account"
          />
        )}
        {(props.userType > 4 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="ServiceProvider"
                checked={props.statusFilter[4]}
                onChange={(e) => props.handleChangeFilter("4", e)}
                canAssign
                canRemove
              />
            }
            label="Service Provider"
          />
        )}
        {(props.userType > 5 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Reseller"
                checked={props.statusFilter[5]}
                onChange={(e) => props.handleChangeFilter("5", e)}
                canAssign
                canRemove
              />
            }
            label="Reseller"
          />
        )}
        {props.userType === -1 && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Distributor"
                checked={props.statusFilter[6]}
                onChange={(e) => props.handleChangeFilter("6", e)}
                canAssign
                canRemove
              />
            }
            label="Distributor"
          />
        )}
      </Menu>
    </div>
  );
  return <div>{html}</div>;
};

const rowDefinitionR = (translate) => {
  if (localStorage.getItem("adminToken")) {
    return [
      {
        id: "userType",
        type: "symbol",
        numeric: false,
        disablePadding: false,
        label: translate("symbol"),
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: translate("sharedName"),
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: translate("userEmail"),
      },
      {
        id: "roleName",
        numeric: false,
        disablePadding: false,
        label: translate("roleName"),
      },
      {
        id: "parentName",
        numeric: false,
        disablePadding: false,
        label: translate("parentName"),
      },
      {
        id: "accountName",
        numeric: false,
        disablePadding: false,
        label: translate("accountName"),
      },
      {
        id: "created",
        numeric: false,
        disablePadding: false,
        label: translate("createdAt"),
      },
    ];
  } else {
    return [
      {
        id: "userType",
        type: "symbol",
        numeric: false,
        disablePadding: false,
        label: translate("symbol"),
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: translate("sharedName"),
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: translate("userEmail"),
      },
      {
        id: "roleName",
        numeric: false,
        disablePadding: false,
        label: translate("roleName"),
      },
      {
        id: "parentName",
        numeric: false,
        disablePadding: false,
        label: translate("parentName"),
      },
      {
        id: "accountName",
        numeric: false,
        disablePadding: false,
        label: translate("accountName"),
      },
      {
        id: "loginAsUser",
        numeric: false,
        disablePadding: false,
        label: translate("loginAsUser"),
      },
      {
        id: "created",
        numeric: false,
        disablePadding: false,
        label: translate("createdAt"),
      },
    ];
  }
};
